import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";

import {
    LgApplicationModule,
    LgMatTrackingService,
    LG_APP_INFO,
    LG_MATOMO_CONFIGURATION,
    LG_NAVIGATION,
} from "@logex/framework/lg-application";
import { LG_APPLICATION_MULTI_EVENT_TRACERS } from "@logex/framework/core";
import { UiCoreModule } from "@logex/framework/ui-core";

import { SharedUtilAuthorizationModule } from "@codman/shared/util-authorization";
import {
    SharedConfigService,
    SharedUtilLogexFrameworkRootModule,
} from "@codman/shared/util-logex-framework-setup";
import { SharedNavigationService } from "@codman/shared/data-access-shared-api";
import { MatomoConfiguration } from "@codman/shared/util-tracking";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        UiCoreModule,
        SharedUtilLogexFrameworkRootModule,
        SharedUtilAuthorizationModule.forRoot(),
        LgApplicationModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (startup: SharedConfigService) => startup.initialize(),
            deps: [SharedConfigService],
            multi: true,
        },
        {
            provide: LG_APP_INFO,
            useFactory: (configService: SharedConfigService) => {
                const config = configService.getConfiguration();
                const buildNumber = config?.version;
                return {
                    environment: config?.environment,
                    fullAppName: "Codman Overview",
                    productId: "overview",
                    toolInstanceName: "",
                    versionNumber: `1.${buildNumber ?? "000"}`,
                    overrideCurrencyPrefix: "",
                    overrideCurrencySuffix: "",

                    doNotDoGaTracking: () => false,
                    isProduction: () => config?.environment === "prod",
                };
            },
            deps: [SharedConfigService],
        },
        {
            provide: LG_NAVIGATION,
            useFactory: (navigationService: SharedNavigationService) =>
                navigationService.getNavigation$(),
            deps: [SharedNavigationService],
        },
        {
            provide: LG_MATOMO_CONFIGURATION,
            useExisting: MatomoConfiguration,
        },
        {
            provide: LG_APPLICATION_MULTI_EVENT_TRACERS,
            useExisting: LgMatTrackingService,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

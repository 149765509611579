import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { auth0GuardCanActivate, auth0GuardCanLoad } from "@codman/shared/util-authorization";
import { AccessDeniedComponent } from "@codman/shared/feature-static-pages";
import { overviewUserAuthGuard } from "@codman/shared/data-access-authorization";

const routes: Routes = [
    {
        path: "",
        pathMatch: "full",
        redirectTo: "dashboard",
    },
    {
        path: "dashboard",
        loadChildren: () =>
            import("@codman/overview/dashboard").then(m => m.OverviewDashboardModule),
        canLoad: [auth0GuardCanLoad],
        canActivate: [auth0GuardCanActivate, overviewUserAuthGuard],
    },
    {
        path: "access-denied",
        component: AccessDeniedComponent,
    },
    {
        path: "**",
        redirectTo: "dashboard",
        pathMatch: "full",
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
